import React from 'react'
import Layout from '../components/Layout'

export default function FAQ() {
    return (
        <Layout>
        <h1>Frequently Asked Questions!</h1>
        </Layout>
    )
}
